<template>
  <div class="box">
    <div id="container" style="width: 100vw; height: 90vh"></div>
    <div class="floating_frame">
      <div class="flex">
        <div class="left circular">寄</div>
        <div class="right">
          <div class="sender">
            <span>{{ expressList.sender }}</span>
            <span>{{ expressList.senderPhone }}</span>
          </div>
          <div class="adress_style">{{ expressList.mailingPoint }}</div>
        </div>
      </div>
      <div class="flex">
        <div class="left circular2">收</div>
        <div class="right">
          <div class="sender">
            <span>{{ expressList.addressee }}</span>
            <span>{{ expressList.senderPhone }}</span>
          </div>
          <div class="adress_style">{{ expressList.pickupPoint }}</div>
        </div>
      </div>
      <div class="line"></div>
      <div class="content">

      </div>
    </div>
  </div>
</template>

<script>
import AMapLoader from "@amap/amap-jsapi-loader";

export default {
  data() {
    return {
      center: [116.397428, 39.90923],
      zoom: 15,
      label: {
        content: "微微一笑绝绝子",
        offset: [10, 12],
      },
      mapData: [
        {
          orderId: "O1544860201805549568",
          deviceId: "Y0004T9C",
          lng: "120.723446",
          lat: "30.707636",
          nowTemperature: "15.5",
        },
      ],
      AMap: null,
      expressList: {
        mailingPoint: "福建省福州市平潭综合实验区电商物流园4仓库", //寄件点
        sender: "小朱", //寄件人
        senderPhone: "13812655236",
        pickupPoint: "浙江省杭州市拱墅区康桥街道金桐雅园东门", //收件点
        addressee: "张小帅", //收件人
      },
      timeList: [
        {
          time: "06-28 18:30",
          state: 4,
          text: "您的快递已签收，签收人在【菜鸟的杭州金桐雅园610号店】领取，如有疑问请电联：业务员：",
        },{
          time: "06-28 18:30",
          state: 3,
          text: "快件已被【菜鸟的杭州金桐雅园610号店】代收，如有疑问请联系：",
        },{
          time: "06-28 18:30",
          state: 2,
          text: "快件已到达【杭州康桥区】",
        },{
          time: "06-28 18:30",
          state: 1,
          text: "【泉州市场部】的保税平潭已收件",
        },{
          time: "06-28 18:30",
          state: 0,
          text: "商品已经下单等待发货",
        }
      ],
    };
  },
  mounted() {
    this.initMap(this.mapData);
  },
  methods: {
    initMap(mapData) {
      AMapLoader.load({
        key: "dbadfe6ff55882da89078419a1faf0f4", // 申请好的Web端开发者Key，首次调用 load 时必填
        version: "2.0", // 指定要加载的 JSAPI 的版本，缺省时默认为 1.4.15
        plugins: [
          "AMap.Autocomplete",
          "AMap.PlaceSearch",
          "AMap.Scale",
          "AMap.OverView",
          "AMap.ToolBar",
          "AMap.MapType",
          "AMap.PolyEditor",
          "AMap.CircleEditor",
        ], // 需要使用的的插件列表，如比例尺'AMap.Scale'等
      })
        .then((AMap) => {
          this.AMap = AMap;
          this.map = new AMap.Map("container", {
            //设置地图容器id
            viewMode: "3D", //是否为3D地图模式
            zoom: 10, //初始化地图级别
            center: [mapData[0].lng, mapData[0].lat], //初始化地图中心点位置
          });
          let marker = "";
          for (let i = 0; i < mapData.length; i++) {
            marker = new AMap.Marker({
              position: new AMap.LngLat(mapData[i].lng, mapData[i].lat), // 经纬度对象，也可以是经纬度构成的一维数组[116.39, 39.9]
              title: i,
              deviceId: mapData[i].deviceId,
            });
            this.map.add(marker);
          }
        })
        .catch((e) => {
          console.log(e);
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.box {
  position: relative;
  .floating_frame {
    width: 90vw;
    background: #ffffff;
    position: absolute;
    top: 50vh;
    left: 5vw;
    border-radius: 5px;
    // padding: 16px 24px 22px 14px;
    padding-top: 16px;
    .flex {
      display: flex;
      align-items: center;
      margin-bottom: 28px;
      .circular {
        width: 29px;
        height: 29px;
        background: #3793f6;
        border-radius: 50%;
        line-height: 29px;
        text-align: center;
        color: #ffffff;
        font-size: 13px;
        font-family: PingFang SC;
        font-weight: 500;
        margin-left: 14px;
        margin-right: 13px;
      }
      .circular2 {
        width: 29px;
        height: 29px;
        background: #50b38c;
        border-radius: 50%;
        line-height: 29px;
        text-align: center;
        color: #ffffff;
        font-size: 13px;
        font-family: PingFang SC;
        font-weight: 500;
        margin-left: 14px;
        margin-right: 13px;
      }
      .right {
        span {
          &:nth-child(2) {
            margin-left: 30px;
          }
        }
        .sender {
          font-size: 15px;
          font-family: PingFang SC;
          font-weight: bold;
          color: #2f3031;
          margin-bottom: 4px;
        }
        .address_style {
          font-size: 13px;
          font-family: PingFang SC;
          font-weight: 500;
          color: #666666;
        }
      }
    }
    .line {
      width: 100%;
      height: 1px;
      background: #e4e4e4;
      margin-bottom: 11px;
    }
    .content{
      margin-left: 14px;
    }
  }
}
</style>
